import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { getCallerIds } from "../helpers/messages";
import {
  errorAlert,
  get,
  getCountryList,
  postJson,
  successAlert,
} from "../helpers/network";
import CountryData from "../helpers/data/countryCode.json";
import bookMark from "../images/bookmark-fill-svgrepo-com.svg";
import SourceSelector from "./SourceSelector";
import { getWidgetId } from "../helpers/globals";

export default function MessageLead({ lead, scriptId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [to, setTo] = useState(lead ? lead.phone_number : "");
  const [message, setMessage] = useState();
  const [callerIds, setCallerIds] = useState();
  const [selectedCallerId, setSelectedCallerId] = useState();
  const [sources, setSources] = useState([]);
  // const [code, setCode] = useState("+1");
  const [allowedCountries, setAllowedCountries] = useState([]);
  const [savedReplies, setSavedReplies] = useState([]);
  const [isSaved, setIsSaved] = useState(false);

  const getAllLeads = async () => {
    let res = await get("user/quick-responses?target=Lead");
    res = await res.json();
    setSavedReplies(res?.data || []);
  };

  useEffect(() => {
    getWidgetCountries();
    getAllLeads();
    async function init() {
      var _callerIds = await getCallerIds();
      setCallerIds(_callerIds);
      if (_callerIds.length) {
        setSelectedCallerId(_callerIds[0]);
      }
    }

    init();
    getUserNumbers();
  }, []);

  const getUserNumbers = async () => {
    var _sources = await get("user/caller-id-with-names");
    _sources = await _sources.json();

    var numbers = [];
    for (var i = 0; i < _sources.data.length; i++) {
      if (i === 0) {
        setSelectedCallerId(
          _sources.data[i]?.phone_number || _sources.data[i]?.number
        );
      }
      if (_sources.data[i]?.name !== "LimeCall Shared Number") {
      numbers.push(_sources.data[i]?.phone_number || _sources.data[i]?.number);
    }
    }
    setSources(numbers);
  };

  const sendmessage = async () => {
    setIsLoading(true);

    var payload = {
      from: selectedCallerId,
      to: to,
      message: message,
      //  lead_id: lead ? lead.id : null,
    };

    if (lead) {
      var result = await postJson("user/send-sms", JSON.stringify(payload));
      result = await result.json();
    } else {
      var result = await postJson(
        "conversation",
        JSON.stringify({
          friendly_name: `+${to}`,
          number: `+${to}`,
          script_id: scriptId,
          source: selectedCallerId,
          body: message,
          message: message,
        })
        // JSON.stringify({
        //   friendly_name: to,
        //   number: to,
        //   script_id: scriptId,
        //   source: selectedCallerId,
        //   body: message,
        //   message: message,
        // })
      );

      result = await result.json();
    }

    if (result.errors && result.errors.length > 0) {
      errorAlert(result.errors[0]);
    } else {
      successAlert("Message has been sent.");
      setMessage("");
    }

    setIsLoading(false);
  };

  const getWidgetCountries = async () => {
    const w_id = await getWidgetId();
    var _sources = await getCountryList(
      `widget/get-widget-countries?widget_id=${w_id}`
    );
    _sources = await _sources.json();
    resetWidgetCountries(_sources?.data?.allowed_countries);
  };

  const resetWidgetCountries = (countries) => {
    const allCountry = CountryData;
    const allowed = allCountry
      .filter((item) => {
        if (countries?.includes(item.name)) {
          return item;
        }
      })
      .map((item) => item.code.toLowerCase());
    setAllowedCountries(allowed);
  };

  const handleClick = () => {
    setIsSaved(true);
  };

  const handleMsgClick = (item) => {
    console.log("item :>> ", item);
    setIsSaved(false);
    setMessage(item?.description);
  };

  return (
    <>
      <h6 className="text-lg">
        Send a message {lead && <span>(#{lead ? lead.id : ""})</span>}
      </h6>
      <div className="mt-4">
        <h6 className="text-lg">From</h6>
        <div className="mb-4">
          <SourceSelector
            sources={sources}
            selectedSource={selectedCallerId}
            setSelectedSource={setSelectedCallerId}
          />
        </div>

        <h6 className="text-lg">To (with country code)</h6>
        {/* <input
          value={to}
          onChange={(e) => {
            setTo(e.target.value);
          }}
          type="text"
          placeholder="+10000000000"
          className="w-full mb-4 text-gray-900 px-4 py-1"
          id="ld_message_to"
        /> */}

        {allowedCountries && allowedCountries?.length ? (
          <PhoneInput
            international
            value={to}
            style={{ color: "black" }}
            onlyCountries={allowedCountries}
            country={"us"}
            enableSearch={true}
            // disabled={inProgress}
            onChange={(e, data) => {
              // setCode("+" + data?.dialCode);
              setTo(e);
            }}
          />
        ) : null}

        <h6 className="text-lg">Message</h6>
        <textarea
          value={message}
          style={{ height: '100px' }}

          onChange={(e) => {
            setMessage(e.target.value);
          }}
          placeholder="Enter message here"
          className="w-full text-gray-900 px-2 py-1"
          id="ld_message_text"
        ></textarea>
        <p>Character Count {message?.length || 0} </p>
        <div className="cursor-pointer">
          <img
            src={bookMark}
            alt="bookMark"
            height="25px"
            width="25px"
            onClick={handleClick}
          />
        </div>
        {isSaved ? (
          <div>
            <div className="saved-popup ">
              <div className="popup-header text-white flex items-center justify-between p-2 border-b border-white">
                <h6 className="mb-0 text-white"> Saved Replies</h6>
                <span onClick={() => setIsSaved(false)}>
                  <svg
                    className="w-7 h-7 cursor-pointer text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                </span>
              </div>
              <div className="popup-body p-2">
                {savedReplies && savedReplies?.length ? (
                  <ul className="mb-0">
                    {savedReplies.map((item, ind) => {
                      return (
                        <li
                          className="text-white popup-lists mb-2 cursor-pointer"
                          onClick={() => handleMsgClick(item)}
                          key={ind}
                        >
                          {item?.title || ""}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <h4 className="text-center mt-3 mb-3">No Data Found</h4>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="mt-3 mb-3">
        <button
          onClick={sendmessage}
          disabled={isLoading}
          id="message-lead"
          className="btn-main !text-lg"
        >
          Send Message
        </button>
      </div>
    </>
  );
}
