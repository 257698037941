import moment from "moment";
import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import FullscreenModal from "../components/FullscreenModal";
import MessageLead from "../components/MessageLead";
import NoMessage from "../components/NoMessage";
import SourceSelector from "../components/SourceSelector";
import SwipeUpModal from "../components/SwipeUpModal";
import { getScritId, getWidgetId } from "../helpers/globals";
import { getConversations, getMessageBody } from "../helpers/messages";
import {
  deleteJson,
  get,
  postJson,
  putJson,
  successAlert,
} from "../helpers/network";
import { classNames, hideLoader, showLoader } from "../helpers/utilities";
import Chat from "./Chat";
let interval = "";
export default function Messages() {
  const [showSwipupModal, setShowSwipupModal] = useState(false);
  const [activeFilter, setActiveFilter] = useState("open");
  const [conversations, setConversations] = useState(null);
  const [openCount, setOpenCount] = useState();
  const [closedCount, setClosedCount] = useState();
  const [selectedConversation, setSelectedConversation] = useState();
  const [teamMembers, setTeamMembers] = useState([]);
  const [scriptId, setScriptId] = useState();
  const [sources, setSources] = useState([]);
  const [selectedSource, setSelectedSource] = useState("all");
  const [noMessage, setNoMessage] = useState(false);
  const [assignList, setAssignList] = useState([]);
  const [membersTeam, setMembersTeam] = useState([]);
  const [admin, setAdmin] = useState([]);

  useEffect(() => {
    processList();
  }, [membersTeam, teamMembers]);

  useEffect(() => {
    return () => {
      hideLoader();
    };
  }, []);

  useEffect(() => {
    async function init() {
      setScriptId(await getScritId());
    }
    if (interval) {
      clearInterval(interval);
    }
    makeInterval();
    fetchMessges(true);
    if (teamMembers && !teamMembers?.length) {
      getTeamMembers();
    }
    if (sources && !sources?.length) {
      getUserNumbers();
    }
    if (membersTeam && !membersTeam?.length) {
      fetchTeams();
    }
    init();
    return () => {
      clearInterval(interval);
    };
  }, [activeFilter]);

  const makeInterval = () => {
    interval = setInterval(() => {
      fetchMessges(false);
    }, 7000);
  };

  const compareNotes = (a, b) => {
    const d1 = new Date(a.created_at);
    const d2 = new Date(b.created_at);
    return d1 - d2;
  };

  const getUserNumbers = async () => {
    var _sources = await get("user/caller-id-with-names");
    _sources = await _sources.json();
    if (_sources?.data && _sources?.data?.length) {
      const data = [];
      const datas = _sources?.data?.map((number, i) => {
        if (i === 0 && _sources?.data?.length > 1) {
          setSelectedSource(number?.number || number?.phone_number);
        }
        if (i + 1 !== _sources?.data?.length) {
          data.push({
            key: number?.name,
            value: number?.number || number?.phone_number,
            text: `${number?.name} ${number?.number || number?.phone_number}`,
          });
        }
      });
      // data.unshift({
      //   key: "main",
      //   value: "all",
      //   text: "All",
      // });
      setSources(data);
    }
  };

  const getTeamMembers = async () => {
    var members = await get("team/members");
    members = await members.json();
    setAdmin([members.data.admin]);
    setTeamMembers(members.data.members);
  };

  async function fetchMessges(_showLoader) {
    if (_showLoader) {
      setConversations([]);
      showLoader();
    }

    var messageState = 1;
    if (activeFilter == "snoozed") {
      messageState = 2;
    }
    if (activeFilter == "closed") {
      messageState = 3;
    }
    var coversationJson = await getConversations(messageState);

    setConversations(
      coversationJson?.conversations?.sort(compareNotes)?.reverse()
    );
    setOpenCount(coversationJson.open);
    setClosedCount(coversationJson.closed);

    hideLoader();
  }

  const homeStatDurationChanged = async (e, con) => {
    var result = await putJson(
      `conversation/${con.id}/update`,
      JSON.stringify({
        state: e?.target?.value,
      })
    );
    if (result?.status === 200) {
      fetchMessges(true);
    }
  };

  useEffect(() => {
    if (conversations && conversations?.length) {
      const someValue = conversations?.some(
        ({ source }) => source === selectedSource
      );
      setNoMessage(someValue);
      if (selectedSource === "all") {
        setNoMessage(true);
      }
    } else {
      setNoMessage(false);
    }
  }, [selectedSource, activeFilter, conversations]);

  const processList = () => {
    const allList = [...teamMembers, ...membersTeam, ...admin];
    const processList = allList.map((item) => {
      return {
        key: item.first_name?.length ? item.id : item.id + "-team",
        value: item.id,
        text: item.first_name?.length ? item.first_name : item.name + " team",
      };
    });
    setAssignList(processList || []);
  };

  const fetchTeams = async () => {
    var members = await get("team/teams?limit=20000&offset=0");
    members = await members.json();
    setMembersTeam(members.data || []);
  };

  const handleChangeAssign = async (e, con) => {
    if (e?.target?.value) {
      let type = "App\\User";
      if (
        assignList
          ?.find(({ value }) => +value === +e?.target?.value)
          ?.key.toString()
          ?.includes("team")
      ) {
        type = "App\\Team";
      }
      const postData = {
        owner_type: type,
        owner_id: e?.target?.value,
        widget_id: getWidgetId(),
        ids: [con?.id],
      };
      var result = await postJson(
        "conversation/assign",
        JSON.stringify(postData)
      );
      result = await result.json();
      if (result) {
        fetchMessges(true);
      }
    }
  };

  const deleteConversation = async (con) => {
    var scriptId = await getScritId();
    if (scriptId && con?.id) {
      var payload = {
        ids: [con.id],
        script_id: scriptId,
      };
      showLoader();
      var result = await deleteJson(
        `conversation/delete`,
        JSON.stringify(payload)
      );
      if (result?.status === 200 || result?.statusText === "OK") {
        fetchMessges(false);
        hideLoader();
      } else {
        hideLoader();
      }
    }
  };

  return (
    <>
      <section className="call-summary mh pb-24 h-full">
        <div className="m-s-top mb-0 mt-2 !top-16 z-10 select-mode">
          <SourceSelector
            sources={sources}
            selectedSource={selectedSource}
            setSelectedSource={setSelectedSource}
          />

          <span
            onClick={() => {
              setShowSwipupModal(true);
            }}
            id="cc_message_btn"
            title="New Message"
          >
            <svg
              id="new-message-btn"
              data-tooltip-content="New message"
              title="Message"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 -mt-2 cursor-pointer text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
              />
            </svg>
          </span>
        </div>

        <div
          className="leads-tabs !top-32 sticky z-10"
          style={{ minHeight: "0px" }}
        >
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li
              onClick={() => {
                setActiveFilter("open");
              }}
              className="nav-item conv-filter"
              data-state="1"
            >
              <a
                className={classNames(
                  activeFilter == "open" ? "active" : "",
                  "nav-link"
                )}
                id="pendingleads"
                data-toggle="tab"
                href="#leads"
                role="tab"
                aria-controls="leads"
                aria-selected="true"
              >
                Open<div id="pendingleadscount"></div>
              </a>
            </li>
            <li
              onClick={() => {
                setActiveFilter("snoozed");
              }}
              className="nav-item conv-filter"
              data-state="3"
            >
              <a
                className={classNames(
                  activeFilter == "snoozed" ? "active" : "",
                  "nav-link"
                )}
                data-toggle="tab"
                href="#"
                role="tab"
                aria-controls="qualified"
                aria-selected="false"
              >
                Snoozed
              </a>
            </li>
            <li
              onClick={() => {
                setActiveFilter("closed");
              }}
              className="nav-item conv-filter"
              data-state="3"
            >
              <a
                className={classNames(
                  activeFilter == "closed" ? "active" : "",
                  "nav-link"
                )}
                data-toggle="tab"
                href="#"
                role="tab"
                aria-controls="qualified"
                aria-selected="false"
              >
                Closed
              </a>
            </li>
          </ul>
        </div>

        <div id="message-holder" className="leads-list">
          {conversations &&
            conversations.map((conversation) => (
              <div
                className={classNames(
                  selectedSource == "all" ||
                    selectedSource == conversation.source
                    ? "show"
                    : "hide",
                  "item conversation-item"
                )}
                key={conversation.id}
                data-id="116"
                data-index="0"
              >
                <span
                  className="flex justify-end -mb-[16px] gap-2 text-sm end"
                  onClick={() => {
                    setSelectedConversation(conversation);
                  }}
                >
                  <span className="text-gray-400">
                    {moment(conversation.created_at).diff(moment.now()) < 0
                      ? moment(conversation.created_at).format("Do MMM")
                      : moment(conversation.created_at).format("HH:mm A")}
                  </span>
                </span>
                <div className="flex flex-start">
                  {conversation.owner_name && (
                    <img
                      onClick={() => {
                        setSelectedConversation(conversation);
                      }}
                      className="h-8 w-8 owner-img"
                      src={`https://ui-avatars.com/api/?name=${conversation.owner_name}&rounded=true`}
                    />
                  )}
                  <div
                    className={`pl-3 w-full ${conversation.messages && conversation.messages.length > 0
                        ? "messag-img"
                        : ""
                      }`}
                  >
                    <div
                      className="company lead_name"
                      onClick={() => {
                        setSelectedConversation(conversation);
                      }}
                    >
                      {conversation && conversation.contact
                        ? conversation.contact.name
                        : conversation.friendly_name}
                    </div>

                    {conversation.unread_messages > 0 && (
                      <span
                        id="unread_notifications_count"
                        className="unread-badge"
                      >
                        {conversation.unread_messages} unread
                      </span>
                    )}
                    {/* {conversation.unread_messages > 0 && ( */}

                    {/* )} */}
                    <p
                      className="text-gray-300 mb-2 p-tag"
                      onClick={() => {
                        setSelectedConversation(conversation);
                      }}
                    >
                      {conversation.messages &&
                        (conversation.messages.length > 0
                          ? getMessageBody(conversation.messages[0])
                          : "No messages")}
                    </p>
                    <span
                      id="unread_notifications_count"
                      className="delete-badge"
                    >
                      <a title="Delete">
                        <svg
                          id="delete-btn"
                          data-tooltip-content="Delete"
                          onClick={() => deleteConversation(conversation)}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6 text-white cursor-pointer"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </a>
                    </span>
                    {/* <div
                      className="flex justify-end gap-2 text-sm end"
                      onClick={() => {
                        setSelectedConversation(conversation);
                      }}
                    >
                      <span className="text-gray-400">
                        via {conversation.source}
                      </span>
                    </div> */}
                  </div>
                </div>
                {/* <div className="flex justify-end gap-2 text-sm end mt-2">
                  <span
                    className={classNames(
                      conversation.state == "active"
                        ? "bg-green-500"
                        : "bg-red-500",
                      "status-assigned"
                    )}
                  >
                    <div className="">
                      <select
                        className="font-bold text-sm bg-transparent focus:outline-none"
                        onChange={(e) =>
                          homeStatDurationChanged(e, conversation)
                        }
                        defaultValue={
                          conversation?.state == "active"
                            ? "1"
                            : conversation?.state == "closed"
                            ? "3"
                            : "2"
                        }
                      >
                        <option value={"1"}>Open</option>
                        <option value={"3"}>Close</option>
                        <option value={"2"}>Snoozed</option>
                      </select>
                    </div>
                  </span>
                  <span
                    className={classNames("bg-green-500", "status-assigned")}
                  >
                    <div className="">
                      <select
                        className="font-bold text-sm bg-transparent focus:outline-none"
                        onChange={(e) => handleChangeAssign(e, conversation)}
                        defaultValue={
                          conversation?.owner_id
                            ? conversation?.owner_id?.toString()
                            : ""
                        }
                      >
                        <option value="">Unassigned</option>
                        {assignList &&
                          assignList?.length > 0 &&
                          assignList?.map((val, ind) => {
                            return (
                              <option key={ind} value={val?.value?.toString()}>
                                {val?.text}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </span>
                </div> */}
              </div>
            ))}
          {!noMessage && (
            <>
              <NoMessage
                sendSMSModal={() => {
                  setShowSwipupModal(true);
                }}
              />
            </>
          )}
        </div>
      </section>
      {selectedConversation && (
        <FullscreenModal
          showTopNav={true}
          component={
            <Chat
              handleReload={() => fetchMessges(true)}
              setShowSwipupModal={setShowSwipupModal}
              sources={sources}
              conversation={selectedConversation}
              onClosed={(refresh) => {
                if (refresh) {
                  fetchMessges(true);
                }
                setSelectedConversation(null);
              }}
              assignList={assignList || []}
              handleChangeAssign={handleChangeAssign}
            />
          }
        />
      )}
      {showSwipupModal && (
        <SwipeUpModal
          component={
            <MessageLead scriptId={scriptId} teamMembers={teamMembers} />
          }
          onClosed={() => {
            setShowSwipupModal(false);
          }}
        />
      )}
      <Tooltip anchorId="new-message-btn" />
    </>
  );
}
