import { useState } from "react";
import { Link } from "react-router-dom";
import { AudioSettings } from "../components/AudioSettings";
import { BusinessHourSettings } from "../components/BusinessHourSettings";
import CallRouting from "../components/CallRouting";
import ProfileSettings from "../components/ProfileSettings";
import VirtualNumber from "../components/VirtualNumber";
import { setAccessToken } from "../helpers/network";
import { classNames } from "../helpers/utilities";
import CallForwarding from "../components/CallForwarding";
import NotificationSettings from "../components/NotificationSettings";
import UserSettings from "../components/UserSettings";

export function Settings() {
  const [activeSetting, setActiveSetting] = useState();

  const changeTab = (_tab) => {
    var tab = _tab == activeSetting ? "" : _tab;
    setActiveSetting(tab);
  };

  return (
    <>
      <section className="settings-page bottom-bar-padding">
        <div className="settings-top sticky top-16 z-10">
          <Link id="goto-home" className="close-settings" to="/">
            <svg
              className="heroicon w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </Link>
          <h1>Settings</h1>
        </div>

        <div className="accordion" id="accordionExample">
          <div className="accordion-item ">
            <h2 className="accordion-header mb-0" id="headingThree">
              <button
                onClick={() => {
                  changeTab("account");
                }}
                className={`${
                  activeSetting == "account" ? "active" : ""
                } text-white border-2  border-slate-800	 accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left rounded-none transition focus:outline-none`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Account Settings
              </button>
            </h2>
            <div
              id="collapseThree"
              className={classNames(
                activeSetting == "account" ? "show" : "collapse",
                "accordion-collapse "
              )}
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-2">
                <ProfileSettings />
              </div>
            </div>
          </div>
          <div className="accordion-item ">
            <h2 className="accordion-header mb-0" id="headingSeven">
              <button
                onClick={() => {
                  changeTab("virtual");
                }}
                className={`${
                  activeSetting == "virtual" ? "active" : ""
                } text-white border-2  border-slate-800	 accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left rounded-none transition focus:outline-none`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Virtual Numbers
              </button>
            </h2>
            <div
              id="collapseThree"
              className={classNames(
                activeSetting == "virtual" ? "show" : "collapse",
                "accordion-collapse "
              )}
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5">
                <VirtualNumber />
              </div>
            </div>
          </div>
          <div className="accordion-item border-md border-gray-900">
            <h2 className="accordion-header mb-0" id="headingOne">
              <button
                onClick={() => {
                  changeTab("business-hours");
                }}
                className={`text-white border-2 border-slate-800 accordion-button relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left rounded-none transition focus:outline-none ${
                  activeSetting == "business-hours" ? "active" : ""
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Business Hours
              </button>
            </h2>
            <div
              id="collapseOne"
              className={classNames(
                activeSetting == "business-hours" ? "show" : "collapse",
                "accordion-collapse"
              )}
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-2">
                <BusinessHourSettings />
              </div>
            </div>
          </div>
          <div className="accordion-item ">
            <h2 className="accordion-header mb-0" id="headingTwo">
              <button
                onClick={() => {
                  changeTab("audio");
                }}
                className={`${
                  activeSetting == "audio" ? "active" : ""
                } text-white border-2 border-slate-800 accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left rounded-none transition focus:outline-none`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Audio Settings
              </button>
            </h2>
            <div
              id="collapseTwo"
              className={classNames(
                activeSetting == "audio" ? "show" : "collapse",
                "accordion-collapse"
              )}
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-2">
                <AudioSettings />
              </div>
            </div>
          </div>

          <div className="accordion-item ">
            <h2 className="accordion-header mb-0" id="headingFour">
              <button
                onClick={() => {
                  changeTab("routing");
                }}
                className={`${
                  activeSetting == "routing" ? "active" : ""
                } text-white border-2  border-slate-800	 accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left rounded-none transition focus:outline-none`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Do not disturb
              </button>
            </h2>
            <div
              id="collapseThree"
              className={classNames(
                activeSetting == "routing" ? "show" : "collapse",
                "accordion-collapse "
              )}
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5">
                <CallRouting />
              </div>
            </div>
          </div>
          <div className="accordion-item border-md border-gray-900">
            <h2 className="accordion-header mb-0" id="headingMain">
              <button
                onClick={() => {
                  changeTab("my-page");
                }}
                className={`text-white border-2 border-slate-800 accordion-button relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left rounded-none transition focus:outline-none ${
                  activeSetting == "my-page" ? "active" : ""
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                My page
              </button>
            </h2>
            <div
              id="collapseThree"
              className={classNames(
                activeSetting == "my-page" ? "show" : "collapse",
                "accordion-collapse "
              )}
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5">
                <button
                  className="text-white border-slate-800 accordion-button relative items-center w-full text-base text-gray-800 text-left rounded-none transition focus:outline-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseMain"
                  aria-expanded="true"
                  aria-controls="collapseMain"
                >
                  <div className="name-style">
                    <h2>{window?.user?.first_name?.charAt(0)}</h2>
                  </div>
                  {window?.user?.first_name + " " + window?.user?.last_name}
                  {window?.user?.personal_link && (
                    <>
                      <br></br>
                      <a href={window?.user?.personal_link} target="_blank">
                        {window?.user?.personal_link}
                      </a>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="accordion-item ">
            <h2 className="accordion-header mb-0" id="headingCallForwarding">
              <button
                onClick={() => {
                  changeTab("forwarding");
                }}
                className={`${
                  activeSetting == "forwarding" ? "active" : ""
                } text-white border-2  border-slate-800	 accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left rounded-none transition focus:outline-none`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                Call forwarding
              </button>
            </h2>
            <div
              id="collapseFour"
              className={classNames(
                activeSetting == "forwarding" ? "show" : "collapse",
                "accordion-collapse "
              )}
              aria-labelledby="headingCallForwarding"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5">
                <CallForwarding />
              </div>
            </div>
          </div>
          <div className="accordion-item ">
            <h2 className="accordion-header mb-0" id="headingNotifications">
              <button
                onClick={() => {
                  changeTab("notification");
                }}
                className={`${
                  activeSetting == "notification" ? "active" : ""
                } text-white border-2  border-slate-800	 accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left rounded-none transition focus:outline-none`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#notificationCollapse"
                aria-expanded="false"
                aria-controls="notificationCollapse"
              >
                Notification
              </button>
            </h2>
            <div
              id="notificationCollapse"
              className={classNames(
                activeSetting == "notification" ? "show" : "collapse",
                "accordion-collapse "
              )}
              aria-labelledby="headingNotifications"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5">
                <NotificationSettings />
              </div>
            </div>
          </div>
          <div className="accordion-item ">
            <h2 className="accordion-header mb-0" id="headingUsers">
              <button
                onClick={() => {
                  changeTab("user");
                }}
                className={`${
                  activeSetting == "user" ? "active" : ""
                } text-white border-2  border-slate-800	 accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left rounded-none transition focus:outline-none`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#userCollapse"
                aria-expanded="false"
                aria-controls="userCollapse"
              >
                Users
              </button>
            </h2>
            <div
              id="userCollapse"
              className={classNames(
                activeSetting == "user" ? "show" : "collapse",
                "accordion-collapse "
              )}
              aria-labelledby="headingUsers"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5">
                <UserSettings />
              </div>
            </div>
          </div>

          <div className="accordion-item ">
            <h2 className="accordion-header mb-0" id="headingFive">
              <button
                onClick={() => {
                  changeTab("support");
                }}
                className="text-white border-2  border-slate-800	 accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left rounded-none transition focus:outline-none text-white-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <a href="http://help.limephone.io/" target="_blank">
                  Help and Support
                </a>
              </button>
            </h2>
          </div>
          <div className="accordion-item ">
            <h2 className="accordion-header mb-0" id="headingSix">
              <button
                onClick={() => {
                  changeTab("feedback");
                }}
                className="text-white border-2  border-slate-800	 accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left rounded-none transition focus:outline-none text-white-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <a href="http://feedback.limephone.io/" target="_blank">
                  Send Feedback
                </a>
              </button>
            </h2>
          </div>
        </div>
        <button
          onClick={(e) => {
            setAccessToken(null);
            window.location.href = "/";
            localStorage.clear();
          }}
          id="signout-button"
          className="mx-4 mb-4 bg-white hover:!bg-[#12c197] hover:!text-white text-dark btn-main !w-[90%]"
        >
          Sign out
        </button>
      </section>
    </>
  );
}
