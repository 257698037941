import moment from "moment";
import { getMessageBody } from "../helpers/messages";
import SingleTick from "./SingleTick";

export default function ChatMessage({ message, conversation }) {
  return (
    <>
      {!message?.conversation_id && message?.note ? (
        <>
          <div
            className={
              "chat-" +
              message.message_type +
              " container-" +
              message.direction +
              " flex gap-2 items-center"
            }
          >
            <div
              className={"msg-" + "note" + " message item conversation-item"}
              data-id="13233"
              data-index="0"
            >
              {<p className="text-white my-3">{message?.note}</p>}

              {message.message_type == "Message" && (
                <div className="flex lead-nav" id="{id}">
                  <div className="list w-full time">
                    <SingleTick status={message.status} />
                    <div className="list-item">
                      {moment(message.created_at).fromNow()}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <img
              title={conversation?.owner_name}
              className="h-8 w-8 owner-img"
              src="https://ui-avatars.com/api/?name=undefined&rounded=true"
            />
          </div>
        </>
      ) : (
        <>
          {message.message_type != "Log" && (
            <div
              className={
                "chat-" +
                message.message_type +
                " container-" +
                message.direction +
                " flex gap-2 items-center"
              }
            >
              <div
                className={
                  "msg-" + message.direction + " message item conversation-item"
                }
                data-id="13233"
                data-index="0"
              >
                {<p className="text-white my-3">{getMessageBody(message)}</p>}

                {message.message_type == "Message" && (
                  <div className="flex lead-nav" id="{id}">
                    <div className="list w-full time">
                      <SingleTick status={message.status} />
                      <div className="list-item">
                        {moment(message.created_at).fromNow()}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <img
                title={conversation?.owner_name}
                className="h-8 w-8 owner-img"
                src={`https://ui-avatars.com/api/?name=${conversation?.owner_name}&rounded=true`}
              />
            </div>
          )}
          {message.message_type == "Log" && (
            <div className="text-white mt-1 mb-1 text-sm">
              {`[${moment(message.created_at).fromNow()}]`}{" "}
              {getMessageBody(message)}
            </div>
          )}
        </>
      )}
    </>
  );
}
