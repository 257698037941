import axios from "axios";
import { useEffect, useState } from "react";
import "./App.css";
import "./css/bootstrap.min.css";
import "./css/style.css";
import { get, getAccessToken } from "./helpers/network";
import { isExtension } from "./helpers/utilities";
import Page from "./Page";
import Login from "./pages/Login";
import { Permissions } from "./pages/Permissions";
import { Route, Routes, useLocation } from "react-router";
import { Dialer } from "./pages/Dialer";

function App() {
  const location =useLocation()
  const [loggedUser, setLoggedUser] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [showLogin, setShowLogin] = useState(false);
  const [needPermissions, setNeedPermissions] = useState(false);

  // if user logged in
  const apiToken = localStorage?.getItem("access_token") || window.apiToken;

  // console.log({apiToken})

  if (apiToken || window?.apiToken) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${apiToken || window?.apiToken
      }`;
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  }

  useEffect(() => {
    async function global_init() {
      console.log("App ready!!!!!");

      // Load global state data
      window.apiToken = await getAccessToken();
      if (window.apiToken && window.apiToken !== "null") {
        setUserToken(window.apiToken);
        const profile = await get("user/profile");
        const profileJson = await profile.json();

        if (profileJson && profileJson.data && profileJson.data.id) {
          window.user = profileJson.data;
          setLoggedUser(profileJson.data);
        } else {
          // setShowLogin(true);
          if (isExtension()) {
            window.open("/src/login.html?forceLogin=true");
            window.close();
          } else {
            setShowLogin(true);
          }
        }
      } else {
        setShowLogin(true);
        if (isExtension()) {
          window.open("/src/login.html?forceLogin=true");
          window.close();
        } else {
          setShowLogin(true);
        }
      }
    }
    global_init();
  }, []);

  return (
    <div id="app" className="flex justify-center">
      <div id="main">
        {/* {location?.pathname !== "/dialer" && <Dialer />} */}
        {userToken && loggedUser && <Page user={loggedUser} />}
        {showLogin && location?.pathname !== '/get-started' ? <Login /> : <Routes>
          {/* <Route path="/get-started" element={<Landing />} /> */}
        </Routes>}
        {needPermissions && <Permissions />}

      </div>
    </div>
  );
}

export default App;
